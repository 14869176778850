import React from "react";
import YouTube from "../../components/mdxComponents/YouTube";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  "Google Analytics Beratung": "/de/google-analytics-beratung",
  "Tag Management": "/de/tag-management"
};

// hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/tag-management"
  },
  {
    hreflang: "de",
    href: "/de/tag-management"
  },
  {
    hreflang: "x-default",
    href: "/en/tag-management"
  }
];

// Schema
const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Was ist ein Tag Management System?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Ein Tag Management System ist eine JavaScript Bibliothek, die auf der kompletten Webseite eingebunden wird. Mit ihr wird die Implementierung von Analytics Tags und Marketing Diensten vorgenommen."
        }
      },
      {
      "@type": "Question",
      "name": "Welches Problem löst ein TMS?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Webseiten mit 20 bis 40 Tags im Head-Bereich sind bis heute keine Seltenheit. Das heißt Programmierer mussten viele Stunden aufwenden, um Codes in Elemente einzufügen. Tag Management Systeme (TMS) lösen diese Probleme. Mit Tag Management sparen Programmierer Zeit und Unternehmen dadurch Geld. Durch Tag Management Systeme können die Regeln für die Ausführung bestimmter JavaScript Codes direkt im TMS Interface bestimmt werden."
      }
    }
  ]
}
`;



const TagManagement = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Tag Management erklärt mit Anbieter-Vergleich \[${currentYear}\]`}
        description="Lern was Tag Management System bedeutet und warum sie so populär geworden sind - samt der Vorteile. Inklusive Vergleichstabelle aller großen TMS-Anbieter."
        lang="de"
        image="tag-management-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="tag-management/tag-management-hero.png"
          alt="Logos aller TMS Platform Anbieter auf dunklem Hintergrund"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Tag Management</H>
        <p>
          Tag Management erfreut sich stetig an steigender Popularität und einer wachsenden Community. Moderne Webseiten kommen heutzutage nicht mehr ohne solche Systeme aus. Es ist eine neue flurierende Tag Management Branche entstanden.
        </p>
        <p>Aber so sah es nicht immmer aus.</p>
        <p>
          Eine gute Veranschaulichung für das wachsende Interesse an Tag Management, sind die weltweiten Suchen nach "Google Tag Manager" - dem populärsten Tag Management System (TMS).
        </p>

        <ImgScreenshot
          src="tag-management/google-tag-manager-growth-of-popularity-past-10-years.png"
          alt="Chart der das steigende Interesse an Google Tag Manager weltweit seit 2010 abbildet"
          caption="Das zunehmende Interesse an Google Tag Manager seit 2010 illustriert das Wachstum der Branche. Ironischerweise hat sich der überordnete Begriff Tag Management nicht so weit verbreitet, wie die Namen der Platform Anbieter."
          className="article-img"
        />
        <H as="h2">Was ist ein Tag Management System?</H>
        <p>
          Ein Tag Management System ist eine JavaScript Bibliothek, die auf der kompletten Webseite eingebunden wird. Mit ihr wird die Implementierung von Analytics Tags und Marketing Diensten vorgenommen.
        </p>
        <p>HTML Tags, JavaScript Code oder Tracking Pixel können mit nur ein paar Klicks installiert werden und geben einer Webseite neue Funktionalität.</p>
        <H as="h3">Beispiele für Tags</H>
        <ul>
          <li>Google Analytics Tracking</li>
          <li>Video Tracking</li>
          <li>Retargeting Pixel (Facebook Ads, Google Ads, Linkedin etc.)</li>
          <li>Formulare</li>
        </ul>
        <p>
          Zukünftige Tag-Implementierungen können nach der Installation in einem grafischen User Interface (GUI) vorgenommen werden und belassen somit den Quelltext unverändert.
        </p>
        <p>Das populärste TMS ist <Link to="/de/was-ist-google-tag-manager">Google Tag Manager</Link> (GTM). GTM kommt mit einer vielzahl verfügbarer <a href="https://tagmanager.google.com/gallery/#/?page=1" target="_blank" noopener>Tag Templates für Drittanbieter</a>, so dass viele Tags einfach per Mausklick implementiert werden können. </p>
        <p>Oft muss nur die User ID in einem Formular eingegeben werden und ggf. ein paar Kategorienamen oder Webadressen.</p>

        <p>Die Implementierung funktioniert auf drei Komponenten basierend:</p>
        <ul>
          <li><strong>Tags</strong> (Code, der der Seite hinzugefügt wird) </li>
          <li><strong>Trigger</strong> (Regeln mit Konditionen, welche bestimmen <b>wann</b> ein Tag ausgeführt wird) </li>
          <li><strong>Variablen</strong> (Dynamische oder statische Daten, zur Nutzung in Tags oder Triggern)</li>
        </ul>

        <ImgScreenshot
          src="generic/google-tag-manager-overview-tab-en.png"
          alt="Google Tag Manager Übersicht in der Applikation"
          className="article-img"
          caption="Übersicht von Google Tag Manager. Auf der linken Spalte findet man Menüpunkte für die Konfiguration von Tags, Triggern und Variablen."
        />


        <p>Die Konfiguration wird durch simple Formulare und Formfelder durchgeführt.</p><p>Dadurch werden Fehler vermieden und die <b>Implementierung wird für digitale Marketer ohne Programmierkenntnisse möglich</b>.</p>

        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-configuration.png"
          alt="Konfiguration eines Klick-Triggers mit CSS-Selektor"
          className="article-img"
          caption="Konfiguration eines Triggers in GTM. Hier wird definiert wann ein Tag ausgeführt wird. In diesem Falle, wenn ein HTML-Element geklickt wird, welches dem CSS-Selektor entspricht."

        />



        <H as="h2">Welches Problem löst ein TMS?</H>
        <p>
          Mit dem Web 2.0 und fortschreitender Marketing-Technologie kamen neue Möglichkeiten für Webmaster Internet Marketing zu betreiben oder das Besucherverhalten zu analysieren.
        </p>
        <p>Die Einbindung solcher Dienste geschieht durch sog. <b>Tags, Pixel oder Code-Snippets</b>. Wird ein solches Tag auf der Webseite implementiert, kann man beginnen den Dienst zu nutzen. </p>
        <p>Mit der Zeit kamen immer mehr Online-Marketing Anbieter mit eigenen Tags auf den Markt und die Analytics Branche hat sich technisch weiterentwickelt. Webseiten mit 20 bis 40 Tags im <code>{"<head>"}</code> sind bis heute keine Seltenheit.</p>

        <p>Das wachsende Problem war jedoch das Management dieser Tags.<br />Wenn man z.B. <Link to="/de/event-tracking">Event-Tracking in Google Analytics einrichten</Link> wollte, mussten die zu trackenden Elemente auf der Webseite mit kleinen JavaScript Snippets ausgestattet werden.</p>
        <p>Für Unternehmen, die mehrere digitale Kampagnen im Jahr betreiben, bedeutete dies, dass jede neue Landingpage mit tracking Tags versehen werden musste. Andernfalls wäre der Erfolg von Kampagnen, die z.B. auf eine Newsletter Registrierung oder den Kauf eines Produktes abzielen, schwieriger zu messen.</p>
        <p>Und wer nicht misst, kann sich nur schwer verbessern.</p>
        <p>Das heißt Programmierer mussten viele Stunden aufwenden, um Codes in Elemente einzufügen.</p>
        <p>Wie diese Codes genau geschrieben und welche Werte in die Variablen der Snippets weitergegeben werden sollten, bekam eine neue Organisationsaufgabe, die für den Programmierer und den Webseitenbetreiber gleichermaßen unbeliebt war.</p>
        <p>Wer sich vorstellt, dass diese Code-Snippets erweitert werden mussten, sobald ein Klick auch mit Facebook Ads oder als Adwords Conversion getrackt werden sollte, erkennt wie arbeitsintensiv und unflexibel das Verwalten von Drittanbieter-Tags war.</p>
        <p>Unternehmen hatten folglich steigende Ausgaben für Webprogrammierung, um digitales Marketing betreiben zu können.</p>
        <p>Die Implementierung konnte nur durch eigene Lösungen von Webbüros skaliert werden, so dass in so einer spezifischen Branche viele das Problem alleine in Silos lösen mussten.</p>
        <p>Der Markt war reif für Innovation.</p>

        <p>Tag Management Systeme (TMS) lösen diese Probleme. <b>Mit Tag Management sparen Programmierer Zeit und Unternehmen dadurch Geld</b>.</p>
        <p>Ironischerweise wird ein TMS auch durch ein Tag im Quelltext auf allen Seiten der Webseite implementiert. Jedoch werden alle weiteren Tags durch das Tag Management System implementiert.</p>
        <p>D.h. der Programmierer muss nur einmal am Anfang den Quelltext bearbeiten, die weiteren Tags werden in einem Tool mit grafischem User-Interface (GUI) implementiert.</p>
        <ImgScreenshot
          src="tag-management/matomo-trigger-types.png"
          alt="Liste verfügbarer Trigger-Typen in Matomo Tag Manager"
          className="article-img"
          caption="Liste von Matomo's Trigger-Typen. Trigger sagen wann ein gewisser Code ausgeführt werden soll und es gibt reichlich viele zur Auswahl - keine Programmierkenntnisse vorausgesetzt."

        />

        <p>Der Arbeitsaufwand ist dadurch weniger und die Implementierung der nachfolgenden Tags <b>stark vereinfacht</b>.</p>
        <p>Durch das vereinfachte Implementieren von Webanalytics- und Marketing-Tags, spezialisierten sich jetzt sog. <Link to="/de/google-tag-manager-consultant">Tag Management Consultants</Link> auf die Konfiguration und das Daten Management der Tags.</p>
        <p>Tag Management Consultant bekam ein neuer Beruf, der sich auf das Programmieren mit JavaScript für Analytics und digitale Marketing-Dienste spezialisiert.</p>
        <p>Durch Tag Management Systeme können die Regeln für die Ausführung bestimmter JavaScript Codes direkt im TMS Interface bestimmt werden.</p>
        <p>Jede Regel und jeder Datenpunkt ist für alle Tags nutzbar, so dass die Implementierung insgesamt besser skaliert werden kann. Außerdem können Tag Management Systeme auf mehrere Domains genutzt werden.</p>
        <p>Es gibt sehr viele Vorteile von Tag Management Systemen. So  viele, dass sich selten über sie beklagt wird.</p>

        <H as="h2">Vorteile</H>
        <p>Der Größte Vorteil von einem Tag Management System ist, dass ein technischer Marketer die Implementierung von Marketing Tags ohne einen Programmierer durchführen kann.</p>
        <p>Der grafische User Interface (GUI) macht es dem digitalen Marketer einfach Tracking Pixel und Master Tags zu installieren - ohne jegliches Hacken im Quelltext. </p>

        <p>Als <b>Beispiel</b>, hier die Liste von digitalen Marketing Tools, welche durch ein paar Mausklicks mit dem <b>Matomo Tag Manager</b> installiert werden können:</p>
        <ImgScreenshot
          src="tag-management/matomo-tag-templates.png"
          alt="Liste von Tag-Typen in Matomo"
          className="article-img"
          caption="Liste von tag-Typen in Matomo. Tags können durch vordefinierte Templates implementiert werden, so dass nur ein Formular mit Eingabefeldern ausgefüllt werden muss."
        />

        <p>Und im <b>Vergleich</b> hier die Liste von Tags, die durch Google Tag Manager installiert werden können. Die Liste ist aus Platzgründen gekürzt aber hier gibts die <a href="https://support.google.com/tagmanager/answer/6106924?hl=de" target="_blank" noopener>Liste aller unterstützten Tags</a>. GTM hat bei Weitem die meisten <strong>Tag Templates</strong> verfügbar.</p>
        <ImgScreenshot
          src="tag-management/gtm-tag-types.png"
          alt="Liste von Tags in GTM"
          className="article-img"
          caption="Tag-Liste in GTM. Die Liste ist aus Platzgründen gekürzt. Es gibt sehr viel mehr standard Tags, als auch Templates von anderen Nutzern."
        />


        <p>Weil Tags innerhalb von Sekunden aktiviert werden können, müssen Marketing Teams nicht länger auf das "nächste Deployment in ein paar Wochen" warten, um Kampagnen messen zu können.<br />
          Der Gebrauch eines TMS <b>spart also Zeit, Kosten und verringert Verwaltungsaufwand</b>.</p>



        <p>Mit dieser errungenen Flexibilität werden agile Workflows möglich in denen Kampagnen schrittweise verbessert werden und Experimente möglich sind.</p>



        <p>Digitale Marketingkampagnen können somit in größerer Variation und zugeschnitten auf die Interessen der Nutzer durchgeführt werden.</p>
        <p>Als Folge verbessert sicht die Konvertierungsrate und es kann besser beurteilt werden, wie Segmente auf unterschiedliche Botschaften reagieren.</p>


        <p>Firmen, die zuvor schon Nutzerdaten gesammelt haben und diese in Datenbanken, CRM Systemen und APIs verfügbar haben, können durch einen <Link to="/de/data-layer">Data Layer</Link> die Daten für Trigger-Regeln im Kampagnentracking nutzen.</p>

        <p>Die Einstellungsmöglichkeiten für Workspaces, Umgebungen und Benutzerrechte sind ein weiterer unterschätzer Vorteil einer Tag Management Lösung. Durch sie kann Zugang für externe Agenturen oder <Link to="/de/google-tag-manager-consultant">Freelancer</Link> gewährt werden.</p><p>Der Zugang kann in Sekunden erteilt werden, so dass Änderungen an einzelnen Tags oder im kompletten Tagging-Plan möglich sind.</p>

        <p>Die <b>Vorteile von Tag Management</b> sind zusammengefasst:</p>
        <ul>
          <li>Kostenersparnis durch niedrigeren Implementierungsaufwand</li>
          <li>Schnelle Implementierung</li>
          <li>Flexibilität - da Änderungen in Sekunden vollbracht werden können</li>
          <li>Robuste Integration von eksternen Datenquellen</li>
          <li>Fähigkeit Zugriffsrechte an externe Partner einfach zu erteilen</li>
        </ul>

        <H as="h3">Nachteile</H>
        <p>Es gibt zugegebenermaßen ein berechtigtes Bedenken, dass der Nutzen eines TMS die allgemeine <b>Ladegeschwindigkeit der Webseite verschlechtert</b>.</p>
        <p>Dies ist jedoch nur zu einem begrenzten Grad möglich, da alle Tags im <Link to="/de/wiki-analytics/container-tag-de">Container Tag</Link> asynchron geladen werden können.</p>
        <p>Webseiten mit einem gut konfigurierten TMS laden meistens sogar schneller, als wenn Tags direkt im Quelltext eingebunden wären.</p>
        <p>Der Mehrgewinn an Ladegeschwindkeit wird den höheren Aufwand für die Verwaltung der Tags vermutlich nicht rechtfertigen.</p>
        <p>Da die Ladegeschwindigkeit von vielen Webmastern oft brutal misachtet wird, gibt es meistens bessere Alternativen die Ladegeschwindigkeit zu verbessern, als Tag Management Tools zu vermeiden.</p>


        <p>Und nun als Übergang zum Platform-Vergleich - welche Firma denkst Du hätte genügend Interesse das schalten von digitalen Kampagnen zu vereinfachen und eine gratis Lösung für die oben genannten Probleme zu entwickeln? Müsste eine Firma sein, die davon profitiert, wenn andere mehr Werbung schalten 😉.</p>



        <H as="h2">Platform Vergleich</H>

        <p>Hier ist ein Vergleich der gängigen Platformen im Tag Management Markt:</p>


        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>

              <th>Preis</th>
              <th>Eigentum<br />der Daten</th>
              <th>Verfügbarkeit<br />von Experten</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>GTM (gratis)</b></td>
              <td>gratis</td>
              <td>nein</td>
              <td>gut</td>
            </tr>
            <tr>
              <td><b>GTM 360</b></td>
              <td>hoch</td>
              <td>ja</td>
              <td>gut</td>
            </tr>
            <tr>
              <td><b>Adobe Launch</b></td>
              <td>hoch</td>
              <td>ja</td>
              <td>mittel</td>
            </tr>
            <tr>
              <td><b>Tealium iQ</b></td>
              <td>mittel</td>
              <td>ja</td>
              <td>schlecht</td>
            </tr>
            <tr>
              <td><b>Matomo</b></td>
              <td>tief</td>
              <td>ja</td>
              <td>schlecht</td>

            </tr>
            <tr>
              <td><b>Piwik</b></td>
              <td>mittel</td>
              <td>ja</td>
              <td>schlecht</td>


            </tr>
          </tbody>
        </table>

        <p>Für große Unternehmen und Enterprises fällt die Wahl für gewöhnlich auf premium Platformen wie <b>Google Tag Manager 360, Adobe Launch</b> (Nachfolger von Adobe DTM) oder <b>Tealium iQ</b>, da sie die Bedürfnisse hinsichtlich des Dateneigentums und der Kundendaten befriedigen.</p>



        <p>Der Preis von GTM 360, Adobe Launch oder Tealium iQ kann nicht genau definiert werden, da Angebote in anpassungsfähige Pakete zusammen mit anderen Analytics Lösungen gebunden werden.</p>



        <p><u>Die obigen Preisschätzungen sollen daher nur als grobe Orientierung dienen</u>. Individuelle Preise variieren bekanntlich.</p>


        <p>Falls Du sehen möchstest wie <b>Tealium's Tag Manager</b> aussieht, wirf einen Blick auf das offizielle Demo Video:</p>
        <YouTube id="HF1ZiPQYP6M" />
        <br />

        <p>Für <b>Adobe Launch</b> habe ich hier ein Beispiel Video, in dem eine Standard-Regel für den Seitenaufruf definiert wird:</p>
        <YouTube id="JR2mRfKU18Y" />
        <br /><br />

        <p>Das am meisten verbreitete Tag Management Tool ist jedoch die <a href="https://tagmanager.google.com/" target="_blank">gratis Version von Google Tag Manager</a>.</p>
        <H as="h2">Anwendungen</H>



        <p>Web Analytics, digitales Marketing und Data Science sind die üblichsten Anwendungsgebiete für Tag Management.</p>

        <H as="h3">Web Analytics</H>

        <p>Analytics Systeme sind schneller und einfacher einzurichten und lassen sich problemlos über mehrere Domains skalieren mit der Hilfe von einem TMS. Nach der Einrichtung von z.B. Google Analytics können weitere Änderungen oder Erweiterungen flexibel, ohne viel Aufwand hinzugefügt werden. Tag Management Systeme haben sich daher als Standard für Analytics Setups durchgesetzt.</p>

        <p><b>Conversion Optimierung</b> (Conversion Rate Optimization, kurz CRO) bedient sich in der Analysephase weitestgehend der selben Metrics oder Dimensionen, die vom Analytics Tool gemessen wurden und ist daher ähnlich einzuordnen.</p>

        <H as="h3">Digitals Marketing</H>
        <p>Werbung auf Facebook, Twitter, Instagram oder digitale Kampagnen mit Retargeting Bannern und Adwords Annoncen sind neben Analytics Systemen die Hauptanwendungen für Tag Manager Tools.</p>
        <p>Die Marketingtechnologie basiert auf Tags, welche wiederum Daten von Webseiten benötigen um Nutzersegmente zu erschaffen. Durch ihre fundamentale Wichtigkeit für den Kampagnenerfolg, müssen Tags kontrollierbar und auf eine skalierbare Weise verwaltet werden können.</p>
        <p><b>Affiliate Marketing</b> basiert auf der Zuordnung von Verkäufen zu anderen Webseiten die den Besucher verwiesen haben. Die Technologie basiert auf Cookie Systematiken, die mit Tag Managern aufgesetzt werden.</p>

        <H as="h3">Data Science</H>

        <p>Die Fortschritte im Bereich der künstlichen Intelligenz (KI), führen einen immer größeren Bedarf an Daten mit sich. Massive Datenmengen sind die Vorraussetzung um Modelle trainieren zu können.</p>
        <p>Daten fürs Training werden häufig programmatisch kopiert (Scraping) wenn sie statisch abzurufen sind. Bei dynamischen Daten kommen häufig Tag Management Lösungen zum Einsatz.</p>

        <H as="h2">Tag Management Beratung</H>
        <p>Consulting für Tag Management wuchs im gleichen Schritt mit der Branche heran. Neue Platformen, Preise und Lösungswege sind entstanden, so dass die Wahl eines Tag Managers komplexer wurde. </p>


        <p>Hinzu kamen schärfere <b>Gesetze zum Schutz von privaten Daten</b> (DSGVO) die die Entscheidungsvorraussetzungen änderten und Anbietern mit Fokus auf Datenschutz einen Schub verliehen hat.</p>



        <p>Beratung für Tag Management fokusiert auf die Wahl der Richtigen Platform, die Implementierung, Validierung und Instandhaltung des Systems.</p>

        <p>Tag Management Berater kommen für gewöhnlich von Agenturen, die auf digitale Annoncen spezialisiert sind oder sie arbeiten als <Link to="/de/google-tag-manager-consultant">freiberufliche Berater</Link></p>

        <H as="h2">Gibt es Fälle in denen ein TMS nicht die richtige Lösung ist?</H>



        <p><b>Ein Tag Management System ist die richtige Wahl für die meisten Webseiten oder Web Apps</b>. Nur in Nischen mit besonderen Ansprüchen an den Datenschutz sollte man auf die Infrastruktur der gewählten Lösung achten.</p>
        <p>Meistens spricht solch ein Fall jedoch nicht gegen die TMS Technologie, sondern eher dafür die Wahl sorgfältig zu überlegen.</p>
        <p>Ein anderer Punkt kann die potentielle Verlangsamung der Ladegeschwindigkeit sein.</p>
        <p>Bei einer schlechten Konfiguration, kann die Ladezeit beeinträchtigt werden. Nischen in denen die sehr viel Werbung auf der eigenen Webseite schalten sind am meisten betroffen, jedoch ist diese Gruppe auch diejenige, die Tag Management am meisten zur Verwaltung der Website braucht.</p><p>Es muss daher abgewogen werden, ob die Gewinne in Ladegeschwindigkeit den Mehraufwand bei der Implementierung von Tracking Pixeln rechtfertigen.</p>

        <p>Abhängig von den Ressourcen der Inhouse Programmierer kann eine Webseite mit Internet Marketing auch erfolgreich ohne ein TMS betrieben werden - aber die sind eher Ausnahmen.</p>

        <p>Jede Webseite, die keine Extremitäten aufweist ist üblicherweise gut beraten zusätzliches HTML und JavaScript Tags mit einem TMS zu verwalten.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>

      </MainContent>
    </React.Fragment>

  </Layout>
);

export default TagManagement;
